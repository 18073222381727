import {
  LIVE_UPDATE_CHANNELS,
  LIVE_UPDATE_CHUNK_TIMEOUT_MS,
  LIVE_UPDATE_FALLBACK_CHANNEL,
  LIVE_UPDATE_INITIAL_TIMEOUT_MS,
  LIVE_UPDATE_MAXIMUM_TIMEOUT_MS,
  LIVE_UPDATE_UI_TOKEN,
} from '@/configs'
import { Capacitor } from '@capacitor/core'
import { KeyboardInfo } from '@capacitor/keyboard'
import * as LiveUpdates from '@capacitor/live-updates'
import { SplashScreen } from '@capacitor/splash-screen'
import { alertController, getPlatforms, popoverController } from '@ionic/vue'
import { FeLogger } from './monitoring'
import {
  deleteLiveUpdateResults,
  getLiveUpdateResults,
  saveLiveUpdateResults,
} from './persistence'

export type DeviceType = 'pc' | 'tablet' | 'phone'
export type DeviceTypeMediaQueryNames =
  | 'queryDesktop'
  | 'queryIpadProLandscape'
  | 'queryTabletLandscape'
  | 'queryTabletPortrait'
  | 'queryWideDesktop'

export default function checkDevice(): DeviceType {
  const platforms = getPlatforms()
  if (platforms.includes('tablet')) {
    return 'tablet'
  }
  if (
    platforms.includes('mobile') ||
    platforms.includes('mobileweb') ||
    platforms.includes('phablet')
  ) {
    return 'phone'
  }
  return 'pc'
}

/**
 * Returns whether the current device is an iOS device, be it a:
 *  - emulated device (using devtools from a modern browser, emulating user agent)
 *  - simulated device (using XCode built-in simulator)
 *  - an actual device (such as a physical iPad)
 */
export function isNativeOrWebIos() {
  return getPlatforms().includes('ios')
}

/**
 * Returns whether the current device is a running this app as a native application.
 */
export function isNativeMobileApp() {
  return Capacitor.isNativePlatform()
}

/**
 * Returns `true` if current device is running this app as a native
 * application and it's on an iOS device.
 */
export function isNativeIos() {
  return isNativeMobileApp() && isNativeOrWebIos()
}

/**
 * Returns true if current device has a touch screen monitor.
 * This could be a smartphone, a tablet or even a laptop.
 */
export function hasTouchScreen() {
  return navigator?.maxTouchPoints > 0
}

/**
 * An helper class to handle window resizing when virtual keyboard is shown
 */
export abstract class KeyboardHelper {
  /**
   * Any virtual keyboard related machinery will ignore keyboard events
   * when the virtual keyboard is smaller than this value (in pixels).
   * For example, this won't center focussed element if keyboard is 70px in height.
   */
  private static KEYBOARD_SIZE_THRESHOLD = 100

  private static intersectionObserver?: IntersectionObserver

  private static get appRoot() {
    return document.querySelector(`[data-app-root]`) as HTMLElement
  }

  private static get appContent() {
    return document.querySelector(`[data-app-content]`) as HTMLElement
  }

  private static get appHeader() {
    return document.querySelector('[data-app-header]') as HTMLElement
  }

  /**
   * Invoked when a virtual keyboard has been shown.
   * If the keyboard has a sufficient height, this method will resize the window and,
   * if the active input element is inside a drawer, this will center said input field.
   * @param info Keyboard activation event
   */
  static keyboardDidShow(info: KeyboardInfo) {
    if (info.keyboardHeight < this.KEYBOARD_SIZE_THRESHOLD) {
      return
    }

    this.appRoot.style.height = `${window.innerHeight - info.keyboardHeight}px`

    const activeElement = document.activeElement as HTMLElement | null
    const drawer = document.querySelector('.drawer-scrollable')

    // If, for some reason, we do not have an active element or we do not have a drawer,
    // we can safely return since we don't have to center the input field.
    if (!activeElement || !drawer) {
      return
    }

    // If we reached this point we create an IntersectionObserver
    // to check whether the active element is contained in the drawer.
    // This will help us with the vertical centering of the active element.
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        const intersecting = entries.find((e) => e.isIntersecting)

        if (intersecting) {
          // Since we got an active item that is intersecting with the drawer,
          // we scroll the drawer so that the relevant part is visible.
          activeElement.scrollTo({
            top: Math.round(intersecting?.boundingClientRect.top || 0),
            behavior: 'instant',
          })

          // Then we can vertically center the active element, so that it's visible in current viewport.
          activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          })

          // finally we can disconnect this observer since we no longer need it.
          this.intersectionObserver?.disconnect()
          this.intersectionObserver = undefined
        }
      },
      { root: drawer }
    )

    // Start observing on current active element.
    this.intersectionObserver.observe(activeElement)
  }

  static keyboardDidHide() {
    // If we had an active element and it was an input element, trigger the blur event
    // so that form validation and such can happen as usual.
    if (document.activeElement instanceof HTMLInputElement) {
      document.activeElement?.blur()
    }

    const headerHeight = this.appHeader.getBoundingClientRect().height || 0

    // Resize some elements to their initial states.
    this.appRoot.style.height = `${window.innerHeight}px`
    this.appContent.style.height = `${window.innerHeight - headerHeight}px`

    // If we had an intersection observer, we can disconnect it.
    this.intersectionObserver?.disconnect()
  }
}

export abstract class LiveUpdatesHelper {
  private static clicksOnVersionNumber = 0

  private static _syncResults?: LiveUpdates.SyncResult
  private static _configs?: LiveUpdates.LiveUpdateConfig

  static get currentChannel() {
    return this._syncResults?.liveUpdate.channel
  }

  static get buildId() {
    if (!this._syncResults?.snapshot?.buildId) {
      this._syncResults = getLiveUpdateResults()
    }
    return this._syncResults?.snapshot?.buildId
  }

  /**
   * Given current deployment environment name, this method returns a Live Update Channel Name.
   *
   * If application is being used for the first time, surely we will not have a last channel.
   * In this case we return the default channel name for this environment, that is the first channel
   * defined in the @see {@link LIVE_UPDATE_CHANNELS} constant for the specified deploy env.
   * If we do not have any configuration for this deployment environment, we return the fallback channel name.
   * In other words, in this case we return @see {@link LIVE_UPDATE_FALLBACK_CHANNEL}.
   * If application was previously used and it had downloaded a live update from a specific channel, we will
   * try to return that channel name. However, if in the meantime it has been removed from the
   * @see {@link LIVE_UPDATE_CHANNELS} for this environment, we will fallback to the first case, acting as if
   * application had never ran.
   * If the Live Update Channel used the last time is still defined for this environment, we return its' name.
   * @param environment Current deploy_env, as returned by Posweb APIs.
   * @returns Channel name
   */
  static getLastChannelUsed(environment: string): string {
    this._syncResults ||= getLiveUpdateResults()

    const channels = LIVE_UPDATE_CHANNELS[environment]
    const lastChannelUsed = this._syncResults?.liveUpdate.channel

    if (lastChannelUsed && channels.includes(lastChannelUsed)) {
      return lastChannelUsed
    }

    // If we reach this point, we can delete stored data, as it is no longer needed, either because we no longer
    // expect this channel for this environment or because we never handled a live update before.
    // In the last case this would be a NOP.
    deleteLiveUpdateResults()

    return channels?.[0] || LIVE_UPDATE_FALLBACK_CHANNEL
  }

  static init(config: LiveUpdates.LiveUpdateConfig): Promise<void> {
    if (!this.canUseLiveUpdates()) {
      return this._hideSplashScreen()
    }

    this._configs = config
    return this._changeChannelAndReload(config.channel)
  }

  /**
   * A method to allow users to change Live Updates channel at runtime.
   * Note that this method will work only if the init method has been called at some point before this one.
   * While we switch Live Update Channel, we will also show the splash screen and hide it once done.
   * @param channel New channel name
   */
  static async useChannel(
    channel: LiveUpdates.LiveUpdateConfig['channel']
  ): Promise<void> {
    if (!this.canUseLiveUpdates()) {
      return this._hideSplashScreen()
    }

    await SplashScreen.show({
      autoHide: false,
    })
    return this._changeChannelAndReload(channel)
  }

  static canUseLiveUpdates(): boolean {
    if (!isNativeMobileApp()) {
      return false
    }

    if (import.meta.env.DEV || import.meta.env.VITE_BYPASS_LIVE_UPDATES) {
      // No need to keep old pieces of information, we can clear them.
      deleteLiveUpdateResults()
      return false
    }

    return true
  }

  /**
   * Allows for Live Updates channel switching, while keeping track of some details needed to run
   * application and to fallback in case something goes wrong with the updating process.
   * Once Live Update has been applied, application will be reloaded so that it can take place and the
   * splashscreen will be automatically hidden.
   * @param channel Live Update channel name
   */
  private static async _changeChannelAndReload(
    channel: LiveUpdates.LiveUpdateConfig['channel']
  ): Promise<void> {
    let canReload = true
    let chunkTimeoutId = -1

    const _preventReloadAndHideSplashScreen = (message: string) => {
      canReload = false
      FeLogger.warn(message)
      return this._hideSplashScreen()
    }

    // Set a timeout that will be cancelled if we can download the first chunk of the live update
    // within a certain amout of time. This way, if store is offline, we do not wait for a longer network
    // timeout, during application bootstrapping. Doing so, we can bail out much faster.
    const initialTimeoutId = window.setTimeout(() => {
      _preventReloadAndHideSplashScreen(
        '[LiveUpdates] Initial connection timed out. Bailing out.'
      )
    }, LIVE_UPDATE_INITIAL_TIMEOUT_MS)

    // Prepare a timeout. If this does not get cancelled fast enough, we skip live
    // updates so that we can proceed with application bootstrapping as usual.
    const maximumTimeoutId = window.setTimeout(() => {
      _preventReloadAndHideSplashScreen(
        '[LiveUpdates] The patching process using Live Updates took too much time. ' +
          'Going on without applying the patch and reloading the application'
      )
    }, LIVE_UPDATE_MAXIMUM_TIMEOUT_MS)

    try {
      // Get current configs, before we apply any new Live Update.
      const currentConfigs = await LiveUpdates.getConfig()

      // Specify the same config object as before, but we update the channel name.
      await LiveUpdates.setConfig({
        ...this._configs,
        channel: channel,
      })

      // Download the latest Live Update for the channel selected.
      this._syncResults = await LiveUpdates.sync(() => {
        // Also, since the download process progressed, we can cancel the timeout for the first chunk and we can reset
        // the timeout for single chunks. This is another optimization trick: if we get stuck downloading a live
        // update, doing so we can go on so that the application still remain usable.
        window.clearTimeout(initialTimeoutId)
        window.clearTimeout(chunkTimeoutId)

        chunkTimeoutId = window.setTimeout(() => {
          // Download of this chunk took too long. Since we cannot cancel the sync process, we have to make sure to
          // signal that we cannot reload the application, as we already have gone on and user might have logged in.
          _preventReloadAndHideSplashScreen(
            '[LiveUpdates] Timed out between chunks download. We will not reload the application.'
          )
        }, LIVE_UPDATE_CHUNK_TIMEOUT_MS)
      })

      FeLogger.info('[LiveUpdates] Sync result:', this._syncResults)

      // Clear all timeouts we set before, as at this point we should either have downloaded a new Live Updated,
      // or decided to use a Live Update that had been downloaded previously.
      window.clearTimeout(maximumTimeoutId)
      window.clearTimeout(initialTimeoutId)
      window.clearTimeout(chunkTimeoutId)

      // We reload the application only if we downloaded a new live update and
      // we did not timed out during the download of a chunk.
      if (canReload) {
        let message: string | undefined = undefined

        if (this._syncResults.activeApplicationPathChanged) {
          message = `[LiveUpdates] Downloaded and installed live update from channel ${this.currentChannel} (Build id: ${this.buildId})`
        } else if (currentConfigs.channel !== channel) {
          message = `[LiveUpdates] Switched to a cached live update channel ${this.currentChannel} (Build id: ${this.buildId})`
        }

        // If we are handling the switching process between two live updates,
        // we log the event, save some info and reload the application.
        if (message) {
          FeLogger.info(message)
          saveLiveUpdateResults(this._syncResults)

          // We switched to a different Live Update, we can reload.
          await LiveUpdates.reload()
        }
      }
    } catch (err) {
      FeLogger.error(
        `[LiveUpdates] Got an exception while syncing or installing a live update. Error: `,
        err
      )
    }

    // We also have to hide the splash screen, as it is not automatic.
    await this._hideSplashScreen()
  }

  static async handleVersionClick() {
    if (!isNativeMobileApp()) {
      return Promise.resolve()
    }

    this.clicksOnVersionNumber++

    if (this.clicksOnVersionNumber === 7) {
      this.clicksOnVersionNumber = 0

      const tokenPrompt = await alertController.create({
        header: 'Enter secret token',
        inputs: [
          {
            placeholder: 'Token',
            name: 'token',
            type: 'password',
          },
        ],
        buttons: ['Ok'],
        backdropDismiss: false,
      })

      await tokenPrompt.present()
      const { data: tokenResponse } = await tokenPrompt.onDidDismiss()

      if (tokenResponse.values.token !== LIVE_UPDATE_UI_TOKEN) {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Invalid secret token',
          buttons: ['Ok'],
        })
        return alert.present()
      }

      const module = await import('@/components/layout/live-update.vue')
      const alert = await popoverController.create({
        component: module.default,
        cssClass: 'liveUpdatesPopover',
      })
      return alert.present()
    }
  }

  static async reset() {
    await LiveUpdates.resetConfig()
    await LiveUpdates.reload()
  }

  private static _hideSplashScreen() {
    return SplashScreen.hide()
  }
}
